<template>
  <v-row justify="center">
    <v-btn
      id="activatorFloatButtonLeadForm"
      fab
      color="primary"
      dark
      @click="
        () => {
          clearForm();
          getTrafficData();
        }
      "
    >
      <v-icon>mdi-account-plus-outline</v-icon>
    </v-btn>
    <v-dialog
      v-if="dialog"
      transition="slide-x-transition"
      v-model="dialog"
      persistent
      :max-width="addExternalBroker ? '75vw' : '45vw'"
      class="rounded-0"
    >
      <v-card v-if="dialog" class="rounded-0">
        <v-form ref="form">
          <v-toolbar color="primary" class="white--text" flat>
            <v-toolbar-title>Nuevo lead</v-toolbar-title>

            <v-toolbar-title
              style="padding-left: 45vw;"
              v-if="addExternalBroker"
              >Datos del broker externo</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog()" icon color="white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-row class="ma-0 pa-0">
            <v-col
              :cols="addExternalBroker ? '8' : '12'"
              class="pa-0 ma-0"
              transition="slide-x-transition"
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <!--Campo de nombre -->
                        <v-col cols="12">
                          <v-text-field
                            dense
                            autocomplete="off"
                            id="txtLeadName"
                            label="Nombre *"
                            hint="Campo obligatorio *"
                            persistent-hint
                            :counter="35"
                            v-model="leadName"
                            :rules="nameRules"
                          >
                            <template v-slot:append>
                              <v-progress-circular
                                v-if="statusIcon.name == 0"
                                size="24"
                                color="primary"
                                indeterminate
                              ></v-progress-circular>

                              <div v-if="statusIcon.name == 1">
                                <v-tooltip v-model="show" top>
                                  <template v-slot:activator="{}">
                                    <v-icon
                                      style="vertical-align: middle"
                                      color="#F1C40F"
                                    >
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </template>
                                  <span>Existen coincidencias </span>
                                </v-tooltip>
                              </div>

                              <v-icon
                                v-if="statusIcon.name == 2"
                                style="vertical-align: middle"
                                color="green"
                              >
                                mdi-checkbox-marked-circle-outline
                              </v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- Campo de ubicación -->

                    <v-col cols="12" md="6">
                      <v-text-field
                        dense
                        :counter="35"
                        label="Ubicación"
                        v-model="leadLocation"
                        hint="Campo opcional"
                        persistent-hint
                        aria-autocomplete="off"
                      ></v-text-field>
                    </v-col>

                    <!--Campo de E-Mail -->

                    <v-col cols="12" md="6">
                      <v-text-field
                        dense
                        label="E-mail (Correo electrónico)"
                        hint="Campo obligatorio en caso de no ingresar un teléfono *"
                        persistent-hint
                        v-model="leadEmail"
                        :rules="emailRules"
                      >
                        <template v-slot:append>
                          <icon-states :status="statusIcon.email" />
                        </template>
                      </v-text-field>
                    </v-col>

                    <!--Campo de Teléfono -->
                    <v-col cols="12" md="6">
                      <country-phone-entry
                        :status="statusIcon.phone"
                        @setPhone="value => (leadPhone = value)"
                        @setCodePhone="value => (countrySelectedCode = value)"
                      />
                    </v-col>

                    <!--Campo de interés en inmobiliaria -->
                    <v-col cols="12" md="6">
                      <super-zones
                        :realEstateList="realEstateListAssigned"
                        @setZones="value => (leadInterestedZones = value)"
                      />
                    </v-col>
                    <!-- Campo de presupuesto Mejorado-->
                    <v-col cols="12" md="6">
                      <v-text-field
                        dense
                        type="number"
                        aria-autocomplete="off"
                        hint="Campo opcional."
                        persistent-hint
                        v-model="leadBudget"
                        label="Presupuesto"
                      >
                        <template v-slot:prepend>
                          <v-menu max-height="400px">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <div
                                  class="green--text"
                                  style="
                                width: 40px;
                                font-size: 1.1em;
                                font-weight: bold;
                              "
                                >
                                  {{ leadCurrency }}
                                </div>
                              </div>
                            </template>

                            <v-list-item v-for="(item, i) in currency" :key="i">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-btn @click="leadCurrency = item">
                                    {{ item }}
                                  </v-btn>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-menu>
                        </template>
                      </v-text-field>
                    </v-col>

                    <!-- Campo de Fuente-->
                    <v-col cols="12" md="6">
                      <v-select
                        :loading="loading"
                        v-model="contactSource"
                        :items="sourcesList"
                        :rules="contactSourceRules"
                        item-text="name"
                        hint="Campo obligatorio *"
                        persistent-hint
                        return-object
                        label="¿Dónde se enteró? (Fuente)"
                      >
                        <template v-slot:item="{ item, on }">
                          <v-list-item v-on="on">
                            <v-list-item-avatar>
                              <div
                                class="
                              pa-2
                              blue
                              lighten-5
                              rounded-circle
                              d-inline-block
                            "
                              >
                                <v-img
                                  v-if="item && item.media"
                                  contain
                                  :src="item.media.src"
                                  width="20px"
                                  height="20px"
                                ></v-img>
                              </div>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-row no-gutters align="center">
                                  <div>{{ item.name }}</div>
                                </v-row>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <!-- Campo de Medio-->

                    <v-col cols="12" md="6">
                      <v-select
                        :loading="loading"
                        v-model="contactMedium"
                        :rules="contactMediumRules"
                        :items="mediumsList"
                        item-text="name"
                        return-object
                        label="¿Cómo nos contactó? (Medio)"
                        hint="Campo obligatorio *"
                        persistent-hint
                      >
                        <template v-slot:item="{ item, on }">
                          <v-list-item v-on="on">
                            <v-list-item-avatar>
                              <div
                                class="
                              pa-2
                              blue
                              lighten-5
                              rounded-circle
                              d-inline-block
                            "
                              >
                                <v-img
                                  v-if="item && item.media"
                                  contain
                                  :src="item.media.src"
                                  width="20px"
                                  height="20px"
                                ></v-img>
                              </div>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-row no-gutters align="center">
                                  <div>{{ item.name }}</div>
                                </v-row>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>

                    <!-- Idioma -->
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="contactLanguage"
                        label="Selecciona una Idioma"
                        :items="languageList"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-translate</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                    <!-- Campo de observaciones -->
                    <v-col cols="12">
                      <v-textarea
                        dense
                        v-model="leadObservations"
                        auto-grow
                        label="Observaciones"
                        rows="1"
                        hint="Campo opcional."
                      ></v-textarea>
                    </v-col>

                    <!-- Campo para seleccionar una fase -->
                    <v-col cols="12" md="6">
                      <v-select
                        ref="phaseLead"
                        label="Fase actual"
                        :items="filtersSt['phases']"
                        :loading="loadingFilters"
                      >
                        <template v-slot:item="{ item }">
                          <v-btn
                            block
                            text
                            class="text-none"
                            @click="selectPhase(item)"
                          >
                            {{ item.text }}
                            <v-spacer></v-spacer>
                          </v-btn>
                        </template>
                      </v-select>
                    </v-col>

                    <!-- Broker asignado mejorado -->

                    <v-col
                      cols="12"
                      md="6"
                      v-show="this.tracking_phase !== 'unassigned'"
                    >
                      <select-contact-broker-advanced
                        :page="'leadCreate'"
                        :lead="null"
                        @getOption="getContactBrokerOption"
                      ></select-contact-broker-advanced>
                    </v-col>

                    <v-col
                      style="color: #154360; font-size: 12px"
                      cols="12"
                      md="6"
                      v-show="this.tracking_phase == 'unassigned'"
                    >
                      <div style="padding-top: 10px">
                        <p class="text-center" style="background: #f9fdff">
                          <v-icon color="#154360">
                            mdi-information-outline
                          </v-icon>
                          El lead entrará a la asignación automática si está
                          activada.
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-col>
            <v-col
              cols="4"
              class="pa-0 ma-0"
              v-if="addExternalBroker"
              height="100%"
              style=" background: #FEF9F1;"
              transition="slide-x-transition"
            >
              <external-broker-search-and-registration
                transition="slide-x-transition"
                typeComponent="Lead"
                @setValidate="value => (validateExternalBroker = value)"
                @setData="value => (dataByExternalBroker = value)"
              />
              <br />
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn
              :disabled="!validateAdvanced"
              @click="handleSubmit"
              color="primary"
            >
              Registrar
            </v-btn>
          </div>
        </v-form>
        <br />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { CreateLead } from "@/api/lead-api.js";
import { GetContactLeadDuplicate } from "@/api/contacts-api.js";
export default {
  components: {
    CountryPhoneEntry: () =>
      import("@/components/lead/components/CountryPhoneEntry.vue"),
    /*  LeadVerification: () =>
      import("@/components/lead/components/LeadVerification.vue"), */
    IconStates: () => import("@/components/lead/components/IconStates.vue"),
    SuperZones: () => import("@/components/lead/components/SuperZones.vue"),
    SelectContactBrokerAdvanced: () =>
      import("@/components/lead/components/SelectContactBrokerAdvanced.vue"),
    ExternalBrokerSearchAndRegistration: () =>
      import(
        "@/components/external-broker/ExternalBrokerSearchAndRegistration.vue"
      )
  },
  data: () => ({
    show: true,
    statusIcon: {
      name: null,
      phone: null,
      email: null
    },
    timer: {
      name: null,
      phone: null,
      email: null
    },
    //Broker externo
    addExternalBroker: false,
    loadingChangeExternalBroker: false,
    validateExternalBroker: false,
    dataByExternalBroker: null,
    //Fin de broker externo..
    leadVerification: false,
    loading: true,
    loadingFilters: false,
    role: localStorage.getItem("user_role"),
    showMenuSelect: false,
    dialog: false, /// <-------cambiar después
    leadName: "",
    foundName: false,
    loadingName: false,
    leadPhone: "",
    countrySelectedCode: "",

    foundPhone: false,
    loadingPhone: false,
    leadEmail: "",
    emailRules: [
      v => /.+@.+/.test(v) || v.length == 0 || "E-mail debe ser valido"
    ],
    foundEmail: false,
    loadingEmail: false,
    leadLocation: "",
    leadObservations: "",
    leadInterestedZones: "",
    leadInterestedZonesRules: [
      value => !!value || "Debes ingresar este valor."
    ],
    leadBudget: "",
    leadCurrency: "MXN",
    autoSuggestedLead: {
      name: "",
      phone: "",
      email: ""
    },
    phasesLeads: [
      {
        text: "Por asignar",
        val: "unssigned",
        value: "unassigned_tracking"
      },
      {
        text: "Asignado",
        val: "assigned",
        value: "assigned_tracking"
      },
      {
        text: "Por perfilar",
        val: "to-contact",
        value: "to-contact_tracking"
      },
      {
        text: "Búsqueda",
        val: "searching",
        value: "searching_tracking"
      },
      {
        text: "Seguimiento",
        val: "tracking",
        value: "tracking_tracking"
      },
      {
        text: "Recorrido",
        val: "scheduled-tour",
        value: "scheduled-tour_tracking"
      },
      {
        text: "Seguimiento post-recorrido",
        val: "finished-tour",
        value: "finished-tour_tracking"
      },
      {
        text: "Ofertando",
        val: "offer",
        value: "offer_tracking"
      },
      {
        text: "Apartado",
        val: "downpayment",
        value: "downpayment_tracking"
      },
      {
        text: "Promesa",
        val: "contract",
        value: "contract_operation"
      },
      {
        text: "Cierre",
        val: "closing-trade",
        value: "closing-trade_operation"
      }
      // {
      //   text: "Cerrado",
      //   value: "finished_operation"
      // },
    ],
    allContactLeads: [],
    currency: ["MXN", "USD"],
    contactBrokerId: "",
    contactBrokerName: "",
    nameRules: [value => !!value || "Ingresa un nombre."],
    phoneRules: [
      // v => !!v || 'Phone number is required',
      // v => v.length >= 10 || 'The phone number is incorrect',
      // v => v.length <= 10 || 'The phone number is incorrect',
      v => Number.isInteger(Number(v)) || "Por favor, ingresa solo números"
    ],
    isActiveRequest: false,
    contactLeadCreatedId: "",
    leadCreatedId: "",
    phase: "active",
    tracking_phase:
      localStorage.getItem("user_role") === "broker"
        ? "assigned"
        : "unassigned",
    operation_phase: "",
    viewPhasesLeads: [],
    tmpRealEstateListAssigned: [],
    sourcesList: [],
    languageList: ["Español", "Inglés"],
    contactLanguage: "Español",
    contactSource: null,
    contactSourceRules: [value => !!value || "Debes seleccionar una fuente."],
    mediumsList: [],
    contactMedium: null,
    contactMediumRules: [value => !!value || "Debes seleccionar un medio."]
  }),
  watch: {
    contactSource(newValue, oldValue) {
      if (newValue.name == "Broker Externo") {
        this.addExternalBroker = true;
        this.changePhoneSearch(this.joinPhone);
      } else {
        this.addExternalBroker = false;
        if (oldValue.name == "Broker Externo") {
          this.changePhoneSearch(this.joinPhone);
        }
      }
    },
    leadName(e) {
      this.changeNameSearch(e);
    },
    leadEmail(e) {
      this.changeEmailSearch(e);
    },
    joinPhone(e) {
      this.changePhoneSearch(e);
    }
  },
  computed: {
    ...mapState({
      realEstateListAssigned: state =>
        state.realEstateDevelopments.realEstateAssign,
      realEstateGroupLoading: state =>
        state.realEstateGroup.realEstateGroupLoading,
      realEstateGroup: state => state.realEstateGroup.realEstateGroup,
      filtersSt: state => state.leads.filters
    }),
    joinPhone() {
      return `${this.countrySelectedCode}${this.leadPhone}`;
    },
    previousValidation() {
      if (
        this.isActiveRequest ||
        !this.canRegister(
          this.leadName,
          this.leadEmail,
          this.leadPhone,
          this.foundEmail,
          this.foundPhone
        )
      ) {
        return false;
      } else {
        return true;
      }
    },
    validateName() {
      if (this.statusIcon.name) {
        if (this.statusIcon.name == 2) return true;
        else return false;
      }
      return false;
    },
    validateEmail() {
      if (this.statusIcon.email) {
        if (this.statusIcon.email == 2) return true;
        else return false;
      }
      return false;
    },
    validatePhone() {
      if (this.statusIcon.phone) {
        if (this.statusIcon.phone == 2) return true;
        else return false;
      }
      return false;
    },
    //Nueva validación cuando hay broker externo...
    validateAddExternalBroker() {
      if (!this.addExternalBroker) return true;
      if (this.validateExternalBroker) return true;
      return false;
    },

    validatePhoneAndEmail() {
      /* 
      Condicionales donde es false:
        -Si los dos son nulos.
        -Si alguno es falso o esta cargando.
      */
      if (this.statusIcon.phone == null && this.statusIcon.email == null)
        return false;
      if (this.statusIcon.phone == 1 || this.statusIcon.email == 1)
        return false;
      if (this.statusIcon.phone == 0 || this.statusIcon.email == 0)
        return false;
      if (this.statusIcon.phone == 2) return true;
      if (this.statusIcon.email == 2) return true;
      return false;
    },
    validateAdvanced() {
      if (!this.previousValidation) return false;
      //if (!this.leadVerification) return false;
      if (!this.contactSource) return false;
      if (!this.contactMedium) return false;
      if (!this.validateAddExternalBroker) return false;
      if (!this.validatePhoneAndEmail) return false;
      return true;
    }
  },
  created() {
    this.fetchRealEstateListStore();
    this.addExternalBroker = false;
    this.tmpRealEstateListAssigned = this.realEstateListAssigned;
    this.initPhaseView();
    this.loadingFilters = true;
    this.fetchGetLeadsFiltersSt().then(() => (this.loadingFilters = false));
  },
  methods: {
    ...mapActions({
      getTrafficDataStore: "getTrafficData",
      notifyBrokerLeadAssigned: "contactBrokers/notifyBrokerLeadAssigned",
      fetchRealEstateListStore:
        "realEstateDevelopments/getRealEstateDevAssgign",
      fetchGetLeadsFiltersSt: "leads/fetchGetLeadsFilters"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading"
    }),
    ///End External Broker....
    canRegister(leadName, leadEmail, leadPhone, foundEmail, foundPhone) {
      if (!leadName) {
        return false;
      } else if (!leadPhone && !leadEmail) {
        return false;
      } else if (foundEmail) {
        return false;
      } else if (foundPhone) {
        return false;
      } else {
        return true;
      }
    },
    getTrafficData() {
      const self = this;
      self.dialog = true;
      self.getTrafficDataStore().then(res => {
        for (let i = 0; i < res.source.length; i++) {
          self.sourcesList.push(res.source[i].source);
        }

        for (let i = 0; i < res.medium.length; i++) {
          self.mediumsList.push(res.medium[i].medium);
        }
        self.loading = false;
      });
    },
    closeDialog() {
      const self = this;
      self.addExternalBroker = false;
      self.dialog = false;
      self.showMenuSelect = false;
      self.tmpRealEstateListAssigned = [];
      self.status.name = null;
      self.status.phone = null;
      self.email.email = null;
      self.$emit("close-menu");
    },
    formatPhone(phone) {
      if (phone !== null && phone) {
        return `${this.countrySelectedCode}${phone}`;
      } else {
        return "";
      }
    },
    // listeners
    handleSubmit() {
      if (!this.isActiveRequest) {
        //  && this.customValidate()
        if (this.$refs.form.validate()) {
          this.handleCreateLead();
        }
      }
    },
    customValidate() {
      // función para validar personalizable
      // agregar regla que al menos correo o teléfono
    },
    changeNameSearch(e) {
      //Si esta en blanco no lo va a registrar...
      //O también si este esta Registrado...
      clearTimeout(this.timer.name);
      if (e.replace(/ /g, "") == "") {
        clearTimeout(this.timer.name);
        this.statusIcon.name = null;
        return 0;
      }
      this.statusIcon.name = 0;

      this.timer.name = setTimeout(() => {
        this.onChangeAutocompleteName(e);
      }, 1000);
    },
    onChangeAutocompleteName(data) {
      let self = this;
      try {
        self.loadingName = true;
        self.foundName = false;
        const search = data;
        GetContactLeadDuplicate({ name: search })
          .then(response => {
            if (response.results > 0) {
              self.statusIcon.name = 1;
            } else {
              self.statusIcon.name = 2;
            }
          })
          .catch(err => {
            throw err;
          });
      } catch (err) {
        self.statusIcon.name = 1;
      }
    },
    //Detect change phone
    changePhoneSearch(e) {
      //Buscar en teléfono...
      this.statusIcon.phone = null;
      clearTimeout(this.timer.phone);
      if (e.length < 7) {
        clearTimeout(this.timer.phone);
        this.statusIcon.phone = null;
        return 0;
      }
      this.statusIcon.phone = 0;
      this.timer.phone = setTimeout(() => {
        this.onChangeAutocompletePhone(e);
      }, 1000);
    },
    onChangeAutocompletePhone(event) {
      const self = this;
      try {
        const search = event;
        GetContactLeadDuplicate({
          phone: search,
          isBrex: this.addExternalBroker
        })
          .then(response => {
            if (response.results > 0) {
              self.statusIcon.phone = 1;
            } else {
              self.statusIcon.phone = 2;
            }
          })
          .catch(err => {
            self.statusIcon.phone = 1;
            throw err;
          });
      } catch (err) {
        self.loadingPhone = false;
        self.foundPhone = false;
        self.statusIcon.phone = 1;
      }
    },
    //Detect Change Search...
    changeEmailSearch(e) {
      this.statusIcon.email = null;
      clearTimeout(this.timer.email);
      //Después agregar expresión regular..
      if (e.replace(/ /g, "") == "" || !e.includes("@")) {
        clearTimeout(this.timer.email);
        this.statusIcon.email = null;
        return 0;
      }
      this.statusIcon.email = 0;
      this.timer.email = setTimeout(() => {
        this.onChangeAutocompleteEmail(e);
      }, 1000);
    },
    onChangeAutocompleteEmail(event) {
      const self = this;
      try {
        self.loadingEmail = true;
        self.foundEmail = false;
        GetContactLeadDuplicate({ email: event })
          .then(response => {
            if (response.results > 0) {
              self.statusIcon.email = 1;
            } else {
              self.statusIcon.email = 2;
            }
          })
          .catch(err => {
            throw err;
          });
      } catch (err) {
        self.statusIcon.email = 1;
        self.loadingEmail = false;
        self.foundEmail = false;
      }
    },
    getContactBrokerOption(option) {
      if (!option) {
        this.contactBrokerId = "";
        this.contactBrokerName = "";
      } else {
        this.contactBrokerId = option._id;
        this.contactBrokerName = option.name;
        this.viewPhasesLeads = this.viewPhasesLeads.splice(1);
      }
    },
    // end listeners
    handleCreateLead() {
      let self = this;
      this.$snotify.async(
        "Registrando lead",
        "Registrando...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              self.showMenuSelect = false;
              self.tmpRealEstateListAssigned = [];
              self.sendCreateLead(resolve, reject);
            }, 1000);
          })
      );
    },
    sendCreateLead(resolveNotify, rejectsNotify) {
      try {
        let self = this;
        self.isActiveRequest = true;
        let brokerId = self.contactBrokerId ? self.contactBrokerId : "";
        let leadToCreate = {
          contactLeadId: self.contactLeadCreatedId
            ? this.contactLeadCreatedId
            : "",
          contactBrokerId: brokerId,
          name: self.leadName ? self.leadName : "",
          zones: self.leadInterestedZones ? self.leadInterestedZones : "",
          phone: self.formatPhone(self.leadPhone),
          email: self.leadEmail ? self.leadEmail : "",
          location: self.leadLocation ? self.leadLocation : "",
          budget: self.leadBudget ? self.leadBudget : "",
          currency: self.leadCurrency ? self.leadCurrency : "",
          source_name: self.contactSource ? self.contactSource.name : "",
          medium_name: self.contactMedium ? self.contactMedium.name : "",
          language: self.contactLanguage,
          observations: self.leadObservations ? self.leadObservations : "",
          phase: self.phase,
          tracking_phase: self.tracking_phase,
          operation_phase: self.operation_phase,
          //Datos del broker externo si este se ha registrado con anterioridad....
          //Verificar si esta habilitado el broker externo..
          ...(this.addExternalBroker && {
            register_external: true,

            external_broker_name: this.dataByExternalBroker.name.trim(),
            external_broker_email: this.dataByExternalBroker.email.replace(
              / /g,
              ""
            ),
            external_broker_phone: (
              this.dataByExternalBroker.countrySelectedCode +
              this.dataByExternalBroker.phone
            ).replace(/ /g, ""),
            real_estate_name: this.dataByExternalBroker.real_estate_name,
            internal_broker_name: brokerId
          })

          //Fin de broker externo...
        };
        CreateLead(leadToCreate)
          .then(response => {
            if (response.Lead) self.leadCreatedId = response.Lead._id;
            setTimeout(() => {
              resolveNotify({
                title: "Lead registrado",
                body: "El lead se ha registrado correctamente.",
                config: {
                  closeOnClick: true,
                  titleMaxLength: 30,
                  timeout: 4000
                }
              });
              // mandar notificación al broker en caso de que se haya seleccionado
              if (this.contactBrokerId) {
                this.$store.dispatch(
                  "contactBrokers/notifyBrokerLeadAssigned",
                  {
                    contactBrokerId: brokerId,
                    leadId: self.leadCreatedId
                  }
                );
              }
              // actualizar lista de leads
              self.dialog = false;
            }, 500);
          })
          .catch(err => {
            throw err;
          })
          .finally(() => {
            self.closeDialog();
            setTimeout(() => {
              self.clearForm();
            }, 5000);
          });
      } catch (err) {
        setTimeout(
          () =>
            rejectsNotify({
              title: "Error!!!",
              body: "Ocurrió un problema al agregar el lead.",
              config: {
                closeOnClick: true
              }
            }),
          2000
        );
      }
    },
    clearForm() {
      this.initPhaseView();
      this.isActiveRequest = false;
      this.showMenuSelect = false;
      this.dialog = false;
      this.leadName = "";
      this.foundName = false;
      this.loadingName = false;
      this.leadPhone = "";
      this.foundPhone = false;
      this.loadingPhone = false;
      this.leadEmail = "";
      this.foundEmail = false;
      this.loadingEmail = false;
      this.leadLocation = "";
      this.leadObservations = "";
      this.leadInterestedZones = "";
      this.leadBudget = "";
      this.leadCurrency = "MXN";
      this.autoSuggestedLead = {
        name: "",
        phone: "",
        email: ""
      };
      this.allContactLeads = [];
      this.contactBrokerId = "";
      this.contactBrokerName = "";
      this.isActiveRequest = false;
      this.contactLeadCreatedId = "";
      this.leadCreatedId = "";
      this.phase = "active";
      (this.tracking_phase =
        localStorage.getItem("user_role") === "broker"
          ? "assigned"
          : "unassigned"),
        (this.operation_phase = "");
      this.viewPhasesLeads = [];
      this.tmpRealEstateListAssigned = [];
      this.sourcesList = [];
      this.contactSource = null;
      this.mediumsList = [];
      this.contactMedium = null;
      this.countrySelected = {
        alpha2: "MX",
        dialCode: "+52",
        nameLatinAmerica: "México"
      };
    },
    selectPhase(value) {
      if (value) {
        this.phase = value.data.phase;
        this.tracking_phase = value.data.tracking_phase;
        this.operation_phase = value.data.operation_phase;
      } else {
        this.phase = "active";
        this.tracking_phase = "unassigned";
        this.operation_phase = "";
      }
    },
    initPhaseView() {
      this.viewPhasesLeads = [];
      this.phasesLeads.forEach(phase => {
        this.viewPhasesLeads.push(phase);
      });
    }
  }
};
</script>
<style>
#activatorFloatButtonLeadForm {
  position: fixed;
  bottom: 48px;
  right: 24px;
}
.v-text-field {
  padding-top: 0px !important;
}
.menu-search {
  display: none;
}
</style>
